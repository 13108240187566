:root {
  --background: #050102;
  --foreground: rgb(199 210 254 / 0.65);
  --font-good-times: "Good Times", sans-serif;
}

html {
  font-family: "SF Pro Display", sans-serif;
  color: rgb(199 210 254 / 65%);
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
}

body {
  color: var(--foreground);
  background: var(--background);
  color: rgb(199 210 254 / 65%);
  font-size: 16px;
  line-height: 1.5;
}

.logo-mozg {
  font-size: 26px;
  font-weight: 600;
  color: #ff271d;
  font-family: var(--font-good-times);
  letter-spacing: 6px;
}
.logo-mozg-white {
  font-size: 26px;
  font-weight: 600;
  // color: #fff;
  font-family: var(--font-good-times);
  letter-spacing: 6px;
}

$theme-colors: (
  "primary": #ff271d,
  "regular": #a5b4fc,
  "info": #0098ea,
  "warning": #ffcc00,
  "danger": #dc3545
) !default;

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@each $color, $value in $theme-colors {
  .text-gradient-#{$color} {
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-image: linear-gradient(to right, #{lighten($value, 10%)} 0%, #ffffff 25%, #{$value} 50%, #{darken($value, 10%)} 75%, #ffffff 100%);
    // text-shadow: 0 0 1px #{lighten($value, 10%)}, 0 0 2px #{lighten($value, 20%)};
  }

  .text-gradient-2-#{$color} {
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-image: linear-gradient(to right, #ffffff 0%, #{$value} 50%, #ffffff 100%);
  }
}

@keyframes flickerAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.8;
  }
  85% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.9;
  }
}

.flicker {
  animation: flickerAnimation 2s ease-out infinite;
}

.holi-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 10%, /* Less bright white */ rgba(139, 69, 19, 0.4) 40%, /* Darker brown */ rgba(0, 0, 0, 0.8) 70%); /* Darker black */
  background-blend-mode: screen;
  mix-blend-mode: lighten;
  pointer-events: none; /* Ensure overlay doesn't block interaction */
  animation: shiftGradient 10s infinite alternate;
}

.holi-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(circle, rgba(255, 0, 0, 0.5) 10%, rgba(255, 165, 0, 0.5) 30%, rgba(0, 255, 0, 0.5) 50%, rgba(0, 0, 255, 0.5) 70%, rgba(75, 0, 130, 0.5) 90%);
  background-blend-mode: screen;
  mix-blend-mode: multiply;
}

/* Animation for vibrant color pulses (optional) */
@keyframes holiPulse {
  0%,
  100% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }
}

.holi-overlay-2 {
  animation: holiPulse 5s infinite ease-in-out;
}

/* Animation for gradient movement to maintain dynamism */
@keyframes shiftGradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.nebula-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 30% 50%, rgba(58, 125, 170, 0.3), transparent), radial-gradient(circle at 70% 20%, rgba(255, 99, 71, 0.2), transparent),
    radial-gradient(circle at 90% 70%, rgba(128, 0, 128, 0.3), transparent);
  opacity: 0.5;
  animation: nebulaMove 30s infinite ease-in-out;
  z-index: 1;
}

/* Nebula movement animation */
@keyframes nebulaMove {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100px 100px;
  }
}

.nebula-bg-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 30% 50%, rgba(30, 60, 90, 0.5), transparent 60%), radial-gradient(circle at 70% 20%, rgba(80, 0, 40, 0.4), transparent 60%),
    radial-gradient(circle at 90% 70%, rgba(50, 0, 80, 0.5), transparent 60%), rgba(0, 0, 0, 0.8); /* Stronger black overlay */
  opacity: 0.8;
  animation: hollywoodNebulaMove 60s infinite ease-in-out;
  z-index: 1;
  transform-origin: center;
}

/* Hollywood-style nebula movement animation */
@keyframes hollywoodNebulaMove {
  0% {
    transform: scale(1) translate(0px, 0px);
    opacity: 0.8;
  }
  25% {
    transform: scale(1.05) translate(-20px, -10px);
    opacity: 0.85;
  }
  50% {
    transform: scale(1.1) translate(-40px, -20px);
    opacity: 0.9;
  }
  75% {
    transform: scale(1.05) translate(-20px, -10px);
    opacity: 0.85;
  }
  100% {
    transform: scale(1) translate(0px, 0px);
    opacity: 0.8;
  }
}

.bg-card-dark-2 {
  background-color: rgb(31 41 55 / 0.4);
  border: 1px solid #1f2937;
  border-radius: 1rem;
}

$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"), ("(", "%28"), (")", "%29")) !default;

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function escape-svg($string) {
  @if str-index($string, "data:image/svg+xml") {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, "url(") == 1 {
        $string: url("#{str-replace(str-slice($string, 6, -3), $char, $encoded)}");
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}
